import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import acquisition from 'assets/acquisition.svg';
import retention from 'assets/retention.svg';
import segment from 'assets/segment.svg';
import attributes from 'assets/attributes.svg';
import events from 'assets/events.svg';
import conversations from 'assets/conversations.svg';
import custom from 'assets/custom.svg';
import teammates from 'assets/teammates.svg';
import Tier from 'components/pricing-tier';
import { Container, Grid, Box, Button } from 'theme-ui';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Grid columns={`repeat(auto-fit, minmax(330px, 1fr))`} mdxType="Grid">
      <Tier title="Essential" price="$50" mdxType="Tier">
        <ol>
          <li parentName="ol"><h3 parentName="li">{`Dashboards`}</h3><ul parentName="li">
              <li parentName="ul"><img src={segment} /> Segment</li>
              <li parentName="ul"><img src={attributes} /> Attributes</li>
            </ul></li>
          <li parentName="ol"><h3 parentName="li">{`Data`}</h3><ul parentName="li">
              <li parentName="ul"><strong parentName="li">{`Daily`}</strong>{` syncs`}</li>
              <li parentName="ul"><strong parentName="li">{`Up to 10,000`}</strong>{` leads, users or companies`}</li>
            </ul></li>
        </ol>
      </Tier>
      <Tier title="Pro" price="$100" highlight mdxType="Tier">
        <ol>
          <li parentName="ol"><h3 parentName="li">{`Dashboards`}</h3><ul parentName="li">
              <li parentName="ul"><img src={segment} /> Segment</li>
              <li parentName="ul"><img src={attributes} /> Attributes</li>
              <li parentName="ul"><img src={conversations} /> Conversations</li>
              <li parentName="ul"><img src={teammates} /> Teammates</li>
            </ul></li>
          <li parentName="ol"><h3 parentName="li">{`Data`}</h3><ul parentName="li">
              <li parentName="ul"><strong parentName="li">{`12 hours`}</strong>{` syncs`}</li>
              <li parentName="ul"><strong parentName="li">{`Up to 100,000`}</strong>{` leads, users or companies`}</li>
            </ul></li>
          <li parentName="ol"><h3 parentName="li">{`Additional features`}</h3><ul parentName="li">
              <li parentName="ul">{`Kick off call`}</li>
              <li parentName="ul">{`Phone support`}</li>
            </ul></li>
        </ol>
      </Tier>
      <Tier title="Premium" price="$200" mdxType="Tier">
        <ol>
          <li parentName="ol"><h3 parentName="li">{`Dashboards`}</h3><ul parentName="li">
              <li parentName="ul"><img src={segment} /> Segment</li>
              <li parentName="ul"><img src={attributes} /> Attributes</li>
              <li parentName="ul"><img src={conversations} /> Conversations </li>
              <li parentName="ul"><img src={teammates} /> Teammates</li>
              <li parentName="ul"><img src={custom} /> Custom charts   </li>
            </ul></li>
          <li parentName="ol"><h3 parentName="li">{`Data`}</h3><ul parentName="li">
              <li parentName="ul"><strong parentName="li">{`6 hours`}</strong>{` syncs`}</li>
              <li parentName="ul"><strong parentName="li">{`Unlimited`}</strong>{` leads, users or companies`}</li>
            </ul></li>
          <li parentName="ol"><h3 parentName="li">{`Additional features`}</h3><ul parentName="li">
              <li parentName="ul">{`Kick off call`}</li>
              <li parentName="ul">{`Phone support    `}</li>
            </ul></li>
        </ol>
      </Tier>
    </Grid>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      