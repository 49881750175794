/** @jsx jsx */
import { jsx, Flex, Grid, Box } from 'theme-ui'

export default ({
  header,
  description,
  image,
  comingSoon = false,
  background = { color: 'transparent' },
  ...props
}) => {
  const computeBackground = background => {
    if (background.url) return `center/cover no-repeat url(${background.url})`
    if (background.color) return background.color
  }

  return (
    <li
      sx={{
        background: computeBackground(background),
      }}
      {...props}
    >
      <Flex
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: 'column',
          py: 4,
        }}
      >
        <h2 sx={{ m: 0 }}>{header}</h2>
        <h3 sx={{ m: 0, fontWeight: 'normal' }}>{description}</h3>
        <Box sx={{ pt: 2 }}>
          <img src={image} />
        </Box>
      </Flex>
    </li>
  )
}
