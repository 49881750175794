import React from 'react'
import { Button } from 'theme-ui'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

export const FreeTrial = ({ ...props }) => (
  <Button
    as={OutboundLink}
    href="https://api.analyticsforintercom.com/auth/intercom"
    variant="primary"
    {...props}
  >
    Start a free trial
  </Button>
)

export const LiveDemo = ({ ...props }) => (
  <Button
    as={OutboundLink}
    href="https://calendly.com/amogh-/30min"
    variant="secondary"
    {...props}
  >
   Get a demo
  </Button>
)

export const GetStarted = ({ ...props }) => (
  <Button
    as={OutboundLink}
    href="https://api.analyticsforintercom.com/auth/intercom"
    variant="primary"
    sx={{ width: '100%' }}
    {...props}
  >
    Get started for free
  </Button>
)
