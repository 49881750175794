// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-guide-mdx": () => import("../src/pages/guide.mdx" /* webpackChunkName: "component---src-pages-guide-mdx" */),
  "component---src-pages-help-data-mdx": () => import("../src/pages/help/data.mdx" /* webpackChunkName: "component---src-pages-help-data-mdx" */),
  "component---src-pages-privacy-mdx": () => import("../src/pages/privacy.mdx" /* webpackChunkName: "component---src-pages-privacy-mdx" */),
  "component---src-pages-index-mdx": () => import("../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-support-mdx": () => import("../src/pages/support.mdx" /* webpackChunkName: "component---src-pages-support-mdx" */),
  "component---src-pages-terms-mdx": () => import("../src/pages/terms.mdx" /* webpackChunkName: "component---src-pages-terms-mdx" */)
}

