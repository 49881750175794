import React from 'react';
export default () => (
  <svg
    width="100%"
    height="33"
    viewBox="0 0 1440 33"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M45.3117 33C39.7752 31.0129 22.5618 26.8258 -2 25.9742V0H1440V20.0129L1428.08 25.9742H1418.65H1405.24C1379.77 25.9742 1374.27 20.0129 1356.95 20.0129H1326.25H1278.94C1262.33 20.0129 1252.77 25.9742 1231.12 25.9742H1174.25C1145.56 25.9742 1141.03 20.0129 1091.2 20.0129H1034.33H958.83C933.161 20.0129 925.611 20.0129 896.922 25.9742C868.233 31.9355 859.173 25.9742 840.047 25.9742H792.736C764.55 25.9742 776.126 20.0129 757 20.0129H711.702C687.543 20.0129 688.046 20.0129 661.874 25.9742C640.936 30.7432 631.004 27.9613 628.655 25.9742C608.187 23.9871 563.425 20.0129 548.124 20.0129C528.998 20.0129 529.502 25.9742 517.422 25.9742H505.342C488.062 23.9871 446.354 21.2052 417.765 25.9742C382.03 31.9355 382.03 25.9742 365.421 25.9742C352.133 25.9742 341.094 22 337.235 20.0129H301.499H230.532L197.313 25.9742H147.485L116.279 33L85.577 25.9742L45.3117 33Z" />
  </svg>
);
