/** @jsx jsx */
import Feature from 'components/feature'
import { jsx, Flex, Grid } from 'theme-ui'
import acquisitionChart from 'assets/acquisitionChart.svg'
import engagementChart from 'assets/engagementChart.svg'
import retentionChart from 'assets/retentionChart.svg'
import revenueChart from 'assets/revenueChart.svg'
import trapezium from 'assets/funnel-level.svg'

export default () => (
  <Flex sx={{ justifyContent: 'center' }}>
    <ul
      sx={{
        position: 'relative',
        listStyle: 'none',
        m: 0,
        p: 0,
        maxWidth: '800px',
        width: '100%',
      }}
    >
      <Level
        header={'Acquisition'}
        description={'See who’s coming in'}
        image={acquisitionChart}
        width={'95%'}
        color="#F1F2F5"
      />

      <Level
        header={'Engagement'}
        description={'See what they’re doing'}
        image={engagementChart}
        width={'90%'}
        color="white"
      />

      <Level
        header={'Retention'}
        description={'See who sticks around'}
        image={retentionChart}
        width={'85%'}
        color="#F1F2F5"
      />

      <Level
        header={'Revenue'}
        description={'Follow the $'}
        image={revenueChart}
        width={'85%'}
        color="white"
      />
    </ul>
  </Flex>
)

const Level = ({ color, width, ...props }) => {
  const funnel = (width, color) => {
    return {
      borderTop: `350px solid ${color}`,
      borderRight: '30px solid #fff',
      borderLeft: '30px solid #fff',
      position: 'absolute',
      content: '""',
      width: width,
      left: '50%',
      top: 0,
      transform: 'translateX(-50%)',
      zIndex: -1,
    }
  }

  return (
    <Feature
      sx={{
        position: 'relative',
        margin: 'auto',
        overflow: 'hidden',
        ':before': funnel(width, color),
      }}
      {...props}
    />
  )
}

// const funnel = {
//   borderTop: '240px solid #F3F0EC',
//   borderRight: '30px solid #fff',
//   borderLeft: '30px solid #fff',
//   position: 'absolute',
//   content: '""',
//   width: width,
//   left: '50%',
//   top: 0,
//   transform: 'translateX(-50%)',
//   zIndex: -1,
// }

//background={background ? { url: trapezium } : { color: 'white' }}
