/** @jsx jsx */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Box, jsx } from 'theme-ui'
import Banner from 'components/banner'

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

export default () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "demo-screen.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Banner color="brightGreen">
      <h1 sx={{ mt: -2 }}>Know how your business is going, with zero setup</h1>
      <Box sx={{ margin: '0 auto', maxWidth: '1000px' }}>
        <Img
          sx={{
            borderRadius: ['4px', '12px'],
            boxShadow: [null, '0px 4px 12px rgba(0, 0, 0, 0.25)'],
          }}
          fluid={data.file.childImageSharp.fluid}
        />
      </Box>
    </Banner>
  )
}
