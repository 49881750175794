import React from 'react';
export default () => (
  <svg
    width="100%"
    height="39"
    viewBox="0 0 1440 39"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M45.3117 1C39.7752 3.22796 22.5618 7.92258 -2 8.87742V38H1440V15.5613L1428.08 8.87742H1418.65H1405.24C1379.77 8.87742 1374.27 15.5613 1356.95 15.5613H1326.25H1278.94C1262.33 15.5613 1252.77 8.87742 1231.12 8.87742H1174.25C1145.56 8.87742 1144.35 11.4062 1094.52 11.4062C1044.69 11.4062 1057.58 16.0312 1035.94 16.0312C1014.3 16.0312 986.004 11.4062 960.335 11.4062C934.666 11.4062 925.611 15.5613 896.922 8.87742C868.233 2.19355 859.173 8.87742 840.047 8.87742H792.736C764.55 8.87742 776.126 15.5613 757 15.5613H711.702C687.543 15.5613 688.046 15.5613 661.874 8.87742C640.936 3.53032 631.004 6.64946 628.655 8.87742C608.187 11.1054 563.425 15.5613 548.124 15.5613C528.998 15.5613 529.502 8.87742 517.422 8.87742H505.342C488.062 11.1054 446.354 14.2245 417.765 8.87742C382.03 2.19355 382.03 8.87742 365.421 8.87742C352.133 8.87742 341.094 13.3333 337.235 15.5613H301.499H230.532L197.313 8.87742H147.485L116.279 1L85.577 8.87742L45.3117 1Z"
      stroke="none"
      stroke-linejoin="round"
    />
  </svg>
);
