/** @jsx jsx */
import React from 'react'
import Img from 'gatsby-image'
import { jsx, Flex } from 'theme-ui'
import Tile from 'components/tile'
import { useStaticQuery, graphql } from 'gatsby'

export default () => {
  const query = useStaticQuery(graphql`
    query {
      segment: file(relativePath: { eq: "segmentExplorerChart.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      attribute: file(relativePath: { eq: "attributeExplorerChart.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Flex
      as={'ul'}
      sx={{
        flexDirection: ['column', 'column', 'row'],
        flexWrap: 'wrap',
        justifyContent: 'center',
        listStyle: 'none',
        p: 0,
        m: 0,
      }}
    >
      <Tile
        header={'Segments explorer'}
        description={'Study Intercom segment membership and trends over time'}
        image={query.segment.childImageSharp.fluid}
        background={{ color: '#F1F2F5' }}
      />

      <Tile
        header={'Attributes explorer'}
        description={
          'Study Intercom attributes distribution and trends over time'
        }
        image={query.attribute.childImageSharp.fluid}
      />

      {/* <Tile
      header={'Events explorer'}
      description={
        'Study who is firing your Intercom events and what happens before and after'
      }
      image={eventExplorerChart}
    />

    <Tile
      header={'Conversation explorer'}
      description={
        'Study how your Intercom attributes are distributed across your users, leads or companies'
      }
      image={attributeExplorerChart}
      background={{ color: '#F3F0EC' }}
    /> */}
    </Flex>
  )
}
