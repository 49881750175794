import Navbar from 'components/navbar';
import Hero from 'components/hero';
import Banner from 'components/banner';
import Funnel from 'components/funnel';
import Explorers from 'components/explorers';
import Pricing from 'components/pricing';
import SampleScreen from 'components/sample-screen';
import SEO from 'components/seo';
import Footer from 'components/footer';
import { jsx, Flex, Box, Label, Input, Container, Button } from 'theme-ui';
import React from 'react';
export default {
  Navbar,
  Hero,
  Banner,
  Funnel,
  Explorers,
  Pricing,
  SampleScreen,
  SEO,
  Footer,
  jsx,
  Flex,
  Box,
  Label,
  Input,
  Container,
  Button,
  React
};