/** @jsx jsx */
import React from 'react'
import { jsx, Flex, Box } from 'theme-ui'
import logo from 'assets/logo.svg'
export default ({ withText = true }) => {
  return (
    <Flex sx={{ alignItems: 'center' }}>
      <img sx={{ margin: 0, width: '20%' }} src={logo} />
      {withText && (
        <Box ml={2}>
          <h3 sx={{ maxWidth: '185px', margin: 0, lineHeight: 1.2 }}>
            Analytics for Intercom
          </h3>
        </Box>
      )}
    </Flex>
  )
}
