export default {
  breakpoints: ['40em', '52em', '64em'],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fonts: {
    body:
      '"HK Grotesk", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading: 'inherit',
    monospace: 'Menlo, monospace',
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 40, 48, 64, 96],
  fontWeights: {
    body: 400,
    heading: 800,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  colors: {
    text: 'black',
    background: 'white',
    primary: 'black',
    secondary: '#30c',
    muted: '#f6f6f6',
    accent: '#adb8dc',
    orange: '#fdc26a',
    lightBrown: '#F1F2F5',
    brightGreen: '#F1F2F5',
  },
  text: {
    heading: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
    },
  },

  buttons: {
    default: {
      boxShadow: '0px 2px 1px rgba(0, 0, 0, 0.25)',
      cursor: 'pointer',
      fontWeight: '600',
      fontSize: 3,
    },
    primary: {
      variant: 'buttons.default',
      color: 'background',
      bg: 'primary',
      '&:hover': {
        bg: 'text',
      },
    },
    secondary: {
      variant: 'buttons.default',
      color: 'primary',
      bg: 'background',
      border: '1px solid black',
      borderRadius: '4px',
    },
  },

  links: {
    default: {
      fontSize: 3,
      pr: 3,
      fontWeight: 'bold',
      color: 'black',
      cursor: 'pointer',
      textDecoration: 'none',
      ':hover': {
        textDecoration: 'underline',
      },
    },
    footer: {
      variant: 'links.default',
    },
  },
  styles: {
    root: {
      h1: {
        fontSize: [5, 5, 6],
        lineHeight: 'heading',
      },
      h2: {
        fontSize: [4, 4, 5],
        lineHeight: 'heading',
      },
      h3: {
        fontSize: [3, 3, 4],
        lineHeight: 'heading',
      },
      button: {
        fontFamily: 'body',
      },
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      fontSize: 3,
    },
  },
}
