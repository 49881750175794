/** @jsx jsx */
import React from 'react'
import { jsx, ThemeProvider, Flex, Box, Label, Input, Button } from 'theme-ui'
import TierHeader from 'components/pricing-tier-header'
import Lozenge from 'components/lozenge'

export default ({ title, price, highlight, ...props }) => {
  return (
    <ThemeProvider
      theme={{
        styles: {
          ol: {
            listStyle: 'none',
            display: 'grid',
            gridGap: 3,
            m: 0,
            p: 0,
            pt: 3,
            gridTemplateRows: '2fr 1fr 1fr',
          },
          li: {
            p: 0,
            m: 0,
            pt: 1,
            lineHeight: 1.3,
          },
          ul: {
            listStyle: 'none',
            p: 0,
            m: 0,
          },
          img: {
            display: 'inline-block',
            verticalAlign: 'middle',
          },
          h3: {
            margin: 0,
          },
        },
      }}
    >
      <div
        sx={{
          background: 'white',
          boxShadow: '2px 3px 10px rgba(0, 0, 0, 0.25)',
          borderRadius: '8px',
          height: '760px',
          width: '330px',
          m: 'auto',
          position: 'relative',
          border: highlight ? 'thick solid' : 'thin solid',
          borderColor: highlight ? 'accent' : 'muted',
          p: 4,
        }}
      >
        {highlight && (
          <Lozenge
            sx={{
              position: 'absolute',
              left: '50%',
              top: '2%',
              width: '240px',
              '-webkit-transform': 'translateX(-50%)',
            }}
          >
            Chosen by most customers
          </Lozenge>
        )}
        <TierHeader title={title} price={price} />
        <Box sx={{ pb: 4, borderBottom: '1px solid black' }} />
        <div {...props} />
      </div>
    </ThemeProvider>
  )
}
