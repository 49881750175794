/** @jsx jsx */
import React from 'react'
import { jsx, Flex, Box, Button } from 'theme-ui'
import { GetStarted } from 'components/button'

export default ({ title, price }) => {
  return (
    <>
      <Flex sx={{ justifyContent: 'space-between', h2: { m: 0, p: 0 } }} py={3}>
        <h2>{title}</h2>
        <Flex>
          <h2>{price}</h2>
          <Box sx={{ alignSelf: 'center' }}>/mo</Box>
        </Flex>
      </Flex>
      <GetStarted />
    </>
  )
}
