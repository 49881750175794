/** @jsx jsx */
import React from 'react'
import { jsx, Box } from 'theme-ui'
import TopEdge from 'components/edge-top'
import BottomEdge from 'components/edge-bottom'

export default ({
  color = 'lightBrown',
  topEdge = false,
  bottomEdge = false,
  height,
  p = 5,
  ...props
}) => {
  return (
    <>
      {topEdge && (
        <Box sx={{ mb: -4, color: color }}>
          <TopEdge />
        </Box>
      )}
      <Box
        sx={{ backgroundColor: color, p: p, textAlign: 'center' }}
        {...props}
      />
      {bottomEdge && (
        <Box sx={{ mt: -3, color: color }}>
          <BottomEdge />
        </Box>
      )}
    </>
  )
}
