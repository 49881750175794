/** @jsx jsx */
import React from 'react'
import { jsx, Flex, Box, Grid } from 'theme-ui'
import { LiveDemo, FreeTrial } from 'components/button'

export default props => {
  return (
    <Flex sx={{ justifyContent: 'center', my: [4, 6] }}>
      <MainCTA {...props} />
    </Flex>
  )
}

const MainCTA = ({ children }) => (
  <Box>
    <h1
      sx={{
        fontSize: [null, '48px'],
        textAlign: 'center',
        m: 3,
        maxWidth: '600px',
      }}
    >
      One click for the ultimate Intercom dashboard
    </h1>
    <Flex sx={{ alignItems: 'center', justifyContent: 'center' }}>
      <LiveDemo sx={{ mx: 3 }} />
      <FreeTrial />
    </Flex>
  </Box>
)
