/** @jsx jsx */
import { jsx } from 'theme-ui'

// need to pass props cos need to pass 'className'
// to style a styled component
export default ({ children, ...props }) => {
  return (
    <div
      sx={{
        backgroundColor: '#adb8dc',
        borderRadius: '12px',
        padding: '1 2',
        fontWeight: 'bold',
        fontSize: '14px',
        textAlign: 'center',
      }}
      {...props}
    >
      {children}
    </div>
  )
}
