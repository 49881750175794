/** @jsx jsx */
import React from 'react'
import { jsx, Flex, Box } from 'theme-ui'
import Img from 'gatsby-image'

export default ({
  header,
  description,
  image,
  background = { color: 'white' },
  ...props
}) => {
  return (
    <li {...props}>
      <Flex
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          backgroundColor: background.color,
          p: 4,
        }}
      >
        <Box sx={{ width: '100%' }}>
          <Img
            sx={{
              borderRadius: '12px',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
            }}
            fluid={image}
          />
        </Box>
        <Box sx={{ pt: 2, maxWidth: '400px' }}>
          <h2 sx={{ m: 0 }}>{header}</h2>
          <h3 sx={{ m: 0, fontWeight: 'normal' }}>{description}</h3>
        </Box>
      </Flex>
    </li>
  )
}
