/** @jsx jsx */
import Logo from 'components/logo'
import { jsx, Flex, Box } from 'theme-ui'
import Link, { ScrollLink } from 'components/link'
import { FreeTrial } from 'components/button'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

const Menu = () => (
  <Flex sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
    <Box mr={3} sx={{ display: ['none', 'inline-block'] }}>
      <a
        sx={{ variant: 'links.default' }}
        href="https://api.analyticsforintercom.com/auth/intercom"
      >
        Sign In
      </a>
      <AnchorLink to="/#pricing" stripHash sx={{ variant: 'links.default' }}>
        Pricing
      </AnchorLink>
    </Box>

    <Box>
      <FreeTrial />
    </Box>
  </Flex>
)

export default () => {
  return (
    <Flex
      width="100%"
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
        p: [3, 4],
        position: 'sticky',
        top: 0,
        zIndex: 2,
        backgroundColor: 'white',
      }}
    >
      <Box
        as={Link}
        sx={{
          ':hover': {
            textDecoration: 'none',
          },
        }}
        to="/"
      >
        <Logo />
      </Box>
      <Menu />
    </Flex>
  )
}
