/** @jsx jsx */
import Logo from 'components/logo'
import { jsx, Flex, Box } from 'theme-ui'
import Link, { ScrollLink } from 'components/link'
import { FreeTrial } from 'components/button'
import Banner from 'components/banner'

const FooterLink = props => <Link sx={{ variant: 'links.footer' }} {...props} />

export default props => (
  <Box mt={'auto'} {...props}>
    <Banner p={2} color="lightBrown">
      <Flex
        width="100%"
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          p: 3,
        }}
      >
        <FooterLink to="/privacy">Privacy</FooterLink>
        <FooterLink to="/guide">Guide</FooterLink>
        <FooterLink to="/support">Support</FooterLink>
        <FooterLink to="/terms">Terms</FooterLink>
      </Flex>
    </Banner>
  </Box>
)
